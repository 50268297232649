.container {
  max-width: 1216px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.siteHeader {
  background-color: #FFFBF0;
  position: sticky;
  top: 0;
  z-index: 1000;
  font-size: 14px;
  & a {
    text-decoration: none;
  }

  @media (min-width: 729px) {
    position: sticky;
  }

  @media (max-width: 767px) {
    padding: 11px 0;
  }
}

.menuScroll {
  padding: 5px 0;
}

.siteHeaderRelative {
  position: relative;
}

.image {
  width: 135px;
  height: 49px;
}

.siteHeaderRow {
  max-width: 1300px;
  width: 100%;
  position: relative;

  @media (max-width: 1550px) {
    max-width: 1140px;
  }

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, max-content);
    grid-gap: 0;
    padding-top: 15px;
  }
}

.siteHeaderLogo {
  max-width: 174px;
  @media (max-width: 767px) {
    display: none;
  }
}

.siteHeaderLogoMobile {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}

.headerMenuAccount {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    display: block;
  }
}

.mobileMenu {
  display: none;
  background: url(/images/menuNew.svg) center/22px no-repeat, #4FAF4D;
  margin-left: 15px;
  margin-top: 6px;
  padding: 11px 11px 8px 9px;
  border-radius: 50% 0 50% 50%;
  width: 34px;
  height: 34px;

  @media (max-width: 767px) {
    display: block;
  }
}

.headerLogoFull {
  @media (max-width: 1080px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.headerLogoIcon {
  display: none;
  max-width: 24px;
  @media (max-width: 1080px) {
    display: block;
  }
  @media (max-width: 767px) {
    display: block;
  }
}

.siteHeaderMenuPanel {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-gap: 20px;
  align-items: center;
  background: #FFFBF0;
  border-radius: 28px;
  padding: 11px 0 18px;
  transition: .3s;

  @media (max-width: 1080px) {
    grid-template-columns: 1fr max-content 4fr;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.logo {
  transition: .6s;
  width: 174px;
}

.list {
  @media (min-width: 1080px) {
    margin-left: 50px;
    margin-top: 10px;
    transition: .3s;
  }
}

.wrapperMobileMenu {
  @media (max-width: 767px) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    //backdrop-filter: blur(2px);
    z-index: 1001;
    background: #FFFFFF;
    width: 100%;
  }
}

.wrapperMobileMenuSecond {
  @media (max-width: 767px) {
    position: absolute;
    right: 0;
    left: 0;
  }
}

.siteHeaderMenuPanelOpened {
  display: block;
  width: 100%;
  height: auto;
  background: #FFFFFF;
  //box-shadow: 0 13px 16px rgb(137 137 137 / 40%);
  border-radius: 0;
  //border-top: 1px solid #e7eded;
  padding: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 99999;
  padding-bottom: 40px;
}

.headerLogin {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 10px;
  align-items: center;
  cursor: pointer;

  & i {
    width: 28px;
    height: 28px;
    display: block;
    background: url('/images/iconUser.svg') center/contain no-repeat;
  }

  & p {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #232E23;
    margin-top: 5px;

    @media (max-width: 950px) {
      display: none;
    }

    @media (max-width: 767px) {
      display: block;
    }
  }

  &:hover i {
    background: url('/images/iconUserActive.svg') center/contain no-repeat;
  }

  &:hover p {
    color: var(--vitaliv-green-color);
    text-decoration: none;
  }

  @media (max-width: 767px) {
    display: flex !important;
    align-items: baseline;
    padding: 8px 0;
    margin: 0;
    text-align: left;
    gap: 15px;
    border-bottom: 1px solid #e1e1e1;
    position: relative;
    margin-right: 5px;

    & i {
      display: inline-block !important;
      margin-right: 0;
      position: relative;
      top: 5px;
      background: url('/images/iconUser.svg') center/contain no-repeat;
    }

    &:hover i {
      opacity: 0.8;
      background: url('/images/iconUserActive.svg') center/contain no-repeat;
    }

    & p {
      font-weight: 600;
      font-size: 15px;
      line-height: 120%;
      text-transform: uppercase;
      color: #181818;
    }

    &:hover p {
      opacity: 0.8;
      color: #181818;
      text-decoration: none;
    }
  }
}

.headerLogin:before {
  @media (max-width: 767px) {
    content: '';
    position: absolute;
    right: 0;
    top: 12px;
    width: 12px;
    height: 20px;
    background: url('/images/arrowNavBar.svg') center no-repeat;
  }
}

.mainMenu {
  li {
    display: inline-block;
  }

  li:not(:last-child) {
    margin-right: 15px;
  }

  a {
    color: #232E23;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.01rem;

    &:hover {
      color: var(--vitaliv-green-color);
    }
  }

  @media (max-width: 767px) {
    margin-top: 8px;

    & li {
      display: block;
      text-align: left;
      //padding: 8px 0;
      //border-bottom: 1px solid #e6f1f1;
      margin: 0 !important;
      position: relative;

      & a {
        display: block;
        font-weight: 600;
        font-size: 16px;
        color: #3F513F;
        padding: 10px 15px;
        text-transform: inherit;
      }
    }
  }
}

//.mainMenu li:before {
//  @media (max-width: 767px) {
//    content: '';
//    position: absolute;
//    right: 0;
//    top: 7px;
//    width: 12px;
//    height: 20px;
//    background: url('/images/arrowNavBar.svg') center no-repeat;
//  }
//}

.activeMenuItem {
  a {
    color: var(--vitaliv-green-color);
  }
}

.mobileMenuOpened {
  display: none;
  width: 16px;
  height: 16px;
  background: url(/images/closeMobileMenu.svg) center/22px no-repeat;
  z-index: 100000;
  @media (max-width: 767px) {
    display: block;
  }
}

.headerCart {
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: 0.015em;
  color: #73818a;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  &:hover {
    opacity: 0.8;
  }

  & i {
    display: block;
    width: 28px;
    height: 27px;
    background-image: url(/images/cartIcon.svg);
    background-size: cover;
    background-position: center/22px;
    background-repeat: no-repeat;
    margin-top: -3px;

    @media (max-width: 767px) {
      margin-top: -6px;
    }
  }

  @media (max-width: 767px) {
    margin-right: 5px;
  }
}

.count {
  position: absolute;
  top: -4px;
  left: 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: #4faf4d;
  padding: 4px 6px 2px;
  border-radius: 100px;
}

.headerScroll {
  padding: 0 6px;
}

.headerCartBlock {
  position: absolute;
  top: 29px;
  right: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  transition: .3s;

  @media (max-width: 1080px) {
    top: 13px !important;
  }

  @media (max-width: 950px) {
    top: 15px;
    gap: 5px;
  }

  @media (max-width: 767px) {
    position: static;
    margin-right: 10px;
  }
}

.headerMenuAccount_navMenu {
  pointer-events: all;
  @media (max-width: 767px) {
    display: none;
    pointer-events: none;
  }
}

.headerMenuAccount_mobileMenu {
  display: none;
  pointer-events: none;
  @media (max-width: 767px) {
    display: block;
    pointer-events: all;
    margin-top: 50px;
    padding: 0 10px;
  }
}

.titleName {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.015em;
  color: #181818;
  margin-bottom: 23px;
}

.text {
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-transform: uppercase;
  color: #181818;
}

.cartWrapper {
  display: flex;
  gap: 8px;
  align-items: baseline;
  padding-bottom: 8px;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
}

.cartWrapper:before {
  content: '';
  position: absolute;
  right: 0;
  top: 7px;
  width: 12px;
  height: 20px;
  background: url('/images/arrowNavBar.svg') center no-repeat;
}

.menuTitle {
  padding: 20px 15px;
  font-size: 18px;
  background-color: #FFFBF0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #232E23;
  line-height: 1.4;
}

.mobileMenuFooter {
  z-index: 10000000;
  position: absolute;
  bottom: 66px;
  left: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialLink {
  margin-right: 10px;
}

.mailLinkWrapper {
  margin-top: 6px;
}

.mailLink {
  color: #4FAF4D;
  font-weight: 600;
  font-size: 14px;
}