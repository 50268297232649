.container {
  max-width: 1400px;
  margin: 0 auto;

  @media (min-width: 1550px) {
    max-width: 1300px;
  }
}

.containerError {
  margin: 60px auto;

  @media (min-width: 1024px) {
    margin: 100px auto;
  }
}

.subtext {
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #73818A;
  margin-bottom: 12px;
  margin-left: 200px;

  @media (max-width: 1023px) {
    font-size: 14px;
    text-align: center;
    margin: 0 24px 14px;
  }
}

.header {
  font-weight: 500;
  font-size: 70px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 60px;
  margin-left: 200px;

  @media (max-width: 1023px) {
    font-size: 34px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 34px;
    margin-left: 0;
  }
}

.bold {
  font-weight: 700;
}

.questionAnswer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 150px 0;
}

.containerQuestion {
  position: relative;

  @media (max-width: 1023px) {
    margin: 0 20px;
  }
}

.buttonWrapper {
  cursor: pointer;
  width: 100%;
  border: 4px dashed #EFEFEF;
  box-sizing: border-box;
  border-radius: 30px;
  text-align: left;
  outline: none;
  padding: 18px 267px;
  transition: 0.4s;
  position: relative;

  @media (max-width: 1023px) {
    padding: 20px 59px 20px 26px;
  }
}

.buttonWrapper:hover {
  border: 4px dashed #A6CBC0;
}

.buttonWrapper.clicked {
  border: 4px dashed #A6CBC0;
  border-bottom: none;
  border-radius: 30px 30px 0 0;
}

.button {
  position: absolute;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 2px;
  top: 45%;
  left: 202px;
  transform: translate(-50%, -50%) rotate(0deg);
  cursor: pointer;
  z-index: 100;
  transition: 1s cubic-bezier(0.2, 0.6, 0.3, 1.1);

  @media (max-width: 1023px) {
    width: 14px;
    height: 14px;
    left: 90%;
  }
}

.button:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3px;
  width: 20px;
  background: #232E23;

  @media (max-width: 1023px) {
    width: 14px;
    height: 2px;
  }
}

.button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 3px;
  background: #232E23;

  @media (max-width: 1023px) {
    width: 2px;
    height: 14px;
  }
}

.button.clicked {
  transform: translate(-50%, -50%) rotate(360deg);
}

.button.clicked:before {
  width: 0;
}

.buttonText {
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #000000;

  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 140%;
  }
}

.panel {
  padding: 0 267px;
  background-color: white;
  overflow: hidden;
  transition: height 0.2s ease-out;
  margin-bottom: 16px;

  &.opened {
    transition: height 0.2s ease-out;
    border: 4px dashed #A6CBC0;
    border-radius: 0 0 30px 30px;
    border-top: none;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  @media (max-width: 1023px) {
    padding: 0 40px 0 26px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1199px) {
    flex-direction: column;
    padding: 0 40px;
  }

  @media (max-width: 1023px) {
    padding: 0 20px;
  }
}

.text {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #181818;
  margin-bottom: 20px;

  @media (max-width: 1023px) {
    font-size: 14px;
  }
}

.buttonBlock {
  text-align: center;
}

.buttonMore {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #4faf4d;
  border-radius: 23px;
  padding: 3px 30px;
  margin-top: 40px;
}

.textMargin {
  position: relative;
  margin-left: 40px;
}

.textMargin:before {
  content: '';
  position: absolute;
  top: 12px;
  left: -24px;
  width: 8px;
  height: 2px;
  background-color: #0D0B1B;
}

.buttonOpenMore {
  display: none;
}

.buttonContainer {
  margin: -50px 20px 20px;
  @media (min-width: 1024px) {
    margin-top: 40px;
    text-align: center;
  }
}

.buttonOpen {
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 0;
  outline: 0;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border: 0;
  background: url("/images/aboutUs/arrow22.svg") 80% 45% no-repeat, #4faf4d;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  border-radius: 35px;
  margin: 0 0;

  @media (min-width: 400px) {
    background: url("/images/arrowButton.svg") 78% 46% no-repeat, #4FAF4D;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
    padding: 22px 0;
    width: 448px;
    margin: 0 auto;
    background: url("/images/aboutUs/arrow22.svg") 93% 47% no-repeat, #4faf4d;
  }
}