.sectionBanner {
  padding-top: 60px;
  border-radius: 0;
  background: #FFECA8;
  overflow: hidden;
  margin-top: 63px;

  @media (min-width: 1080px) {
    //border-radius: 30px;
    padding-top: 116px;
    margin-top: 140px;
  }
}

.errorPageContainer {
  margin-top: 0;
}

.sectionBannerContainer {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 1550px) {
    max-width: 1300px;
  }
}

.sectionBannerInner {
  display: block;

  @media (min-width: 1080px) {
    display: flex;
    align-items: start;
  }
}

.sectionBannerPic img {
  max-width: 100%;
  display: inline-block;
  vertical-align: top
}

.sectionBannerPic {
  margin-top: 40px;
  text-align: center;
  flex: 1 1 100%;
  width: 100%;
  margin-left: 0;
  margin-bottom: -8px;

  @media (min-width: 1080px) {
    align-self: flex-end;
    flex: 0 1 428px;
    margin-left: 100px;
    margin-top: 0;
    margin-bottom: -9px;
    margin-right: 60px;
  }
}

.sectionBannerContent {
  flex: 1
}

.sectionBannerText span {
  position: relative;
  z-index: 1;
}

.sectionBannerText span:before {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 22px;
  background: #2ACEEE;
  z-index: -1;
}

.sectionBannerText {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 700;
  max-width: 625px;
  color: #232E23;

  @media (min-width: 1080px) {
    font-size: 43px;
    line-height: 150%;
    margin-top: 30px;
  }
}

.sectionBannerFoot {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  @media (min-width: 1080px) {
    margin-top: 40px;
    margin-bottom: 40px;
    display: block;
  }
}

.apps {
  display: flex;
  flex-wrap: wrap;
  gap: 12px
}
.appsLink {
  display: inline-block;
  vertical-align: top;
  height: 50px
}
.appsLink:hover {
  opacity: .8
}
.appsImg {
  width: auto;
  height: 100%
}