.root {
  // position: fixed;

  // /* color with alpha channel */
  // background-color: rgba(0, 0, 0, 0.6); /* 0.7 = 70% opacity */

  // /* stretch to screen edges */
  // top: 0;
  // left: 0;
  // bottom: 0;
  // right: 0;
  // z-index: 999;
  width: 100%;
  height: 100%;
}

.banner_mobile {
  // max-width: 320px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: linear-gradient(162.39deg, #fdfff4 0%, #eaffea 100%);
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  &_top {
    padding: 68px 16px 30px;
  }

  &__container {
    padding: 30px 16px 40px;
    text-align: center;
    color: #253238;
  }

  &__header {
    margin: 0 0 8px;
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }

  &__header_size {
    margin: 0 0 20px;
    font-weight: 600;
  }

  &__text {
    margin: 0 0 21px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.01em;

    &_size {
      line-height: 140%;
      letter-spacing: 0.015em;
    }

    &_align {
      text-align: left;
      margin: 0;
    }

    &_notifications2 {
      margin: 30px 30px 56px;
    }
  }

  &__title {
    margin: 40px 0 0;
    font-weight: 800;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.015em;
    color: #222d35;
  }

  &__button {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    padding: 17px 40px;
    background: #4faf4d;
    box-shadow: 0 13px 16px rgba(137, 137, 137, 0.4);
    border-radius: 50px;
    border: none;

    &_notification {
      color: #4faf4d;
      background: #ffffff;
      border: 2px solid #4faf4d;
      box-sizing: border-box;
    }
  }
  &__button2 {
    padding: 17px 30px;
    margin-bottom: 16px;
  }

  &__list {
    margin: 0 0 40px;
    padding: 0;
    list-style: none;
  }

  &__item {
    display: flex;
    gap: 8px;
    margin-bottom: 22px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__list2 {
    padding: 0 0 30px;
    margin: 0;
    list-style: none;
    text-align: left;
  }

  &__item2 {
    background: #f8fffe;
    border: 2px solid #e1e1e1;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 12px;

    &:first-child {
      margin-bottom: 16px;
    }
  }

  &__title2 {
    margin: 0 0 2px;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.32px;
    color: #1b1b1b;
  }

  &__text2 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: #666666;
  }

  &__image {
    width: 100%;
  }

  &__image_love {
    margin: 30px 0;
  }

  &__list3 {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item3 {
    display: flex;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 25.1463px;
    margin-bottom: 6px;
    padding: 18px 20px 18px 14px;

    &:last-child {
      margin-bottom: 21px;
    }
  }

  &__wrap {
    margin-left: 7px;
    text-align: left;
  }
  &__title3 {
    margin: 0 0 6px 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.32px;
    color: #1b1b1b;
  }

  &__text3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: #666666;
  }

  &__now {
    margin: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #3f3f3f;
    opacity: 0.5;
  }
}

.span__color {
  color: #73818a;

  &-green {
    color: #4faf4d;
    font-weight: 600;
  }
}

.span__weight {
  font-weight: 800;

  &_600 {
    font-weight: 600;
    color: #000000;
  }
}
.span__green {
  color: #4faf4d;
  font-weight: 900;
}
