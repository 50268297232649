.container {
  margin: 80px 20px 40px;
  text-align: center;

  @media (min-width: 1080px) {
    margin: 150px 20px 0;
  }
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 120%;
  text-align: center;
  color: #2C2C2A;
  margin-bottom: 12px;

  @media (min-width: 1080px) {
    font-size: 90px;
    line-height: 140%;
    margin-bottom: 0;
  }
}

.text {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #3F513F;
  max-width: 300px;
  margin: 0 auto 60px;

  @media (min-width: 1080px) {
    font-size: 34px;
    line-height: 150%;
    margin: 0 auto 54px;
    max-width: 488px;
  }
}

.button {
  background: url("/images/arrowButton.svg") 73% 46% no-repeat, #4FAF4D;
  border-radius: 100px;
  width: 100%;
  padding: 17.5px 0 16.5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;

  @media (min-width: 400px) {
    background: url("/images/arrowButton.svg") 70% 46% no-repeat, #4FAF4D;
  }

  @media (min-width: 1080px) {
    background: url("/images/arrowButton.svg") 68% 46% no-repeat, #4FAF4D;
    font-size: 20px;
    max-width: 468px;
  }
}

.link {
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #4FAF4D;
  margin-top: 24px;
  cursor: pointer;

  @media (min-width: 1080px) {
    font-size: 22px;
    margin-top: 28px;
  }
}