.container {
  max-width: 1300px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  @media(max-width: 1550px) {
    max-width: 1140px;
  }

  @media(max-width: 1024px) {
    max-width: 415px;
  }
}

.desktop {
  display: block;

  @media(max-width: 1024px) {
    display: none !important;
  }
}

.mobile {
  display: none;

  @media(max-width: 1024px) {
    display: block;
  }
}

.mobileNav {
  display: block;

  @media(min-width: 1024px) {
    display: none !important;
  }
}

.followBlock {
  display: none;
  @media(max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.desktopLogo {
  display: flex;
  justify-content: flex-end;

  @media(max-width: 1024px) {
    display: none;
  }
}

.containerBlock {
  display: flex;
  gap: 30px;

  @media(max-width: 1024px) {

  }
}

.blockMobile {
  @media(max-width: 1024px) {
    flex-direction: column;
    //align-items: center;
  }
}

.titleBlock {
  font-weight: 700;
  font-size: 22px;
  line-height: 124%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;

  @media(max-width: 1024px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }
}

.siteFooter {
  background: #151815;
  padding: 70px 0 24px 0;

  @media(max-width: 1024px) {
    padding: 10px 0 24px 0;
  }
}

.logoClub {
  max-width: 100%;
  width: auto;
  height: auto
}

.navList {
  max-width: 240px
}

.widthTitle {
  width: 250px;

  @media(max-width: 1024px) {
    width: 175px;
  }
}

.block {
  //display: grid;
  //grid-template-columns: 1fr 255px 1fr 217px;
  display: flex;
  //justify-content: space-between;
  grid-gap: 30px;

  &:not(:last-child) {
    margin-bottom: 48px;

    @media(max-width: 1024px) {
      margin-bottom: -20px;
    }
  }

  @media(max-width: 1024px) {
    gap: 10px;
  }

  @media(max-width: 767px) {
    grid-template-columns: 1fr 1fr;

    &:first-child {
      grid-template-columns: 1fr;

      & .navList {
        max-width: none;
      }
    }
  }
}

.logo {
  display: block;
  //max-width: 130px
}

.copyright {
  color: var(--blue-grey-color-300);
  font-size: 14px;
  cursor: pointer;
  line-height: 24px;
  margin-top: 8px;
}

.copyrightLink {
  text-decoration: underline;
  color: var(--vitaliv-green-color);
}

.navNew {
  flex: 1;
  //padding-right: 10px;

  @media(max-width: 768px) {
    margin-top: 20px;
  }
}

.bottom {
  margin-bottom: 7px !important;
}

.navTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #181818;
  margin-bottom: 29px;
}

.navItem {
  margin-bottom: 14px;
  line-height: 1;

  span {
    font-size: 16px;
    color: var(--blue-grey-color-300);
  }

  &:last-child {
    margin-bottom: 0
  }
}

.wrapperLists {
  display: flex;
  gap: 38px;
}

.list {
  margin-top: 20px;
  //min-width: 175px;
}

.item {
  margin-bottom: 10px;
  cursor: pointer;
}

.listIcon {
  display: flex;
  gap: 5px;
  margin-left: -10px;

  @media(max-width: 1024px) {
    margin-top: 10px;
  }
}

.listApp {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: baseline;

  @media(max-width: 1024px) {
    justify-content: start;
  }
}

.mobileLogo {
  text-align: center;
}

.listPolicy {
  @media(max-width: 1024px) {
    justify-content: center;
    margin-left: -30px;
    flex-wrap: wrap;
  }
}

.emailTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #FFFFFF;
  text-decoration: underline;

  @media(max-width: 1024px) {
    width: 70px;
  }
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #797979;
}

.textPolicy {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #B9B9B9;
  position: relative;
  margin-left: 24px;
}

.textPolicy:hover {
  color: #4FAF4D;
}

.textPolicyMobile:after {
  @media(max-width: 1024px) {
    content: '';
    position: absolute;
    top: 3px;
    right: -18px;
    width: 5px;
    height: 5px;
    background: url("/images/iconFooter.svg");
  }
}

.textPolicy:before {
  content: '';
  position: absolute;
  top: 3px;
  left: -18px;
  width: 5px;
  height: 5px;
  background: url("/images/iconFooter.svg");

  @media(max-width: 1024px) {
    display: none;
  }
}

.navLink {
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #B9B9B9;
  text-decoration: none;
  white-space: nowrap;

  @media(max-width: 1024px) {
    font-size: 14px;
    line-height: 140%;
    white-space: normal;
  }

  &:hover {
    color: #4FAF4D;
  }
}

.bottomLine {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  @media(max-width: 1024px) {
    margin-top: 70px;
  }

  @media(max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.plusBlockOpen {
  @media(max-width: 1024px) {
    padding-bottom: 14px;
    border-bottom: 2px solid #464643;
    position: relative;
  }
}

.openBlock {
  @media(max-width: 1024px) {
    padding-bottom: 14px;
    border-bottom: 2px solid #464643;
    position: relative;
  }
}

.plusBlockOpen:before {
  @media(max-width: 1024px) {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background: url("/images/plusFooter.svg");
    animation: .2s linear 0s normal none running plusClose;
  }
}

.plusBlock {
  position: relative;
}

.plusBlock:before {
  @media(max-width: 1024px) {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background: url("/images/plusFooter.svg");
    transform: rotate(45deg);
    animation: .2s linear 0s normal none running plus;
  }
}

@keyframes plus {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes plusClose {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wrapperMobileList {
  gap: 20px;
}

.disclaimer {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  max-width: 863px;
  text-align: center;
  color: #FFFFFF;
}

.bottomMenu {
  margin-top: 0;

  li {
    display: inline-block;

    a {
      font-size: 14px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &:not(:last-child) {
      margin-right: 20px
    }
  }

  @media(max-width: 768px) {
    margin-top: 20px;
  }
}

.disclaimerModalTitle {
  margin-bottom: 14px;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

.navListSocial {
  display: flex;
  gap: 24px;
}

.topNavNew {
  margin-top: 97px;
}