.container {
  width: 100%;
  min-height: 54px;
  z-index: 10000000;
  position: fixed;
  bottom: 0;
  background: #FFFFFF;
  border-top: 1px solid #E3E3E3;

  @media (min-width: 729px) {
    display: none;
  }
}

.list {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.item {
  width: 65px;
  text-align: center;
  padding-top: 7px;
}

.item a {
  display: grid;
  gap: 5px;
  position: relative;
}

.activeItem {
  border-top: 2px solid #4FAF4D;
}

.text {
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #CBCBC8;
}

.textActive {
  color: #4FAF4D;
}

.count {
  position: absolute;
  top: -4px;
  right: 8px;
  z-index: 1;
  background: #FFD956;
  border: 1.4px solid #FFFFFF;
  border-radius: 8px 0 8px 8px;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  color: #232E23;
  padding: 2px 6px 1px;
}

.countNot {
  display: none;
}