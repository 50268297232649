.heroMain {
  background-color: #4faf4d;
  background-image: url(/images/aboutUs/icon4.svg);
  background-repeat: no-repeat;
  padding: 0 20px 30px;
  height: auto;
  background-position: bottom 25% left 5%;
  background-size: 300px;

  @media (min-width: 1080px) {
    height: 830px;
    background-position: top right 5%;
    padding: 0 20px;
    background-size: 550px;
  }
}

.scaling {
  @media (min-width: 1080px) {
    zoom: 1;
  }
}

.scalingTwo {
  @media (min-width: 1080px) {
    zoom: 0.8;
  }

  @media (min-width: 1550px) {
    zoom: 1;
  }
}

.heroMainContainer {
  position: relative;
  height: 100%;
  max-width: 1590px;
  margin: 0 auto
}

.heroMainContainerTwo {
  max-width: 1300px;
}

.heroMainInfo {
  padding-top: 30px;
  padding-bottom: 26px;
  position: relative;
  z-index: 1;

  @media (min-width: 1080px) {
    padding-top: 146px;
    padding-bottom: 0;
  }
}

.heroMainContent {
  max-width: 100%;
  text-align: center;

  @media (min-width: 1080px) {
    max-width: 600px;
    text-align: left;
  }
}

.heroMainHeadline {
  font-weight: 700;
  //text-transform: uppercase;
  color: #fff;
  margin: 0;
  text-align: center;
  font-size: 40px;
  line-height: 1.5;

  @media (min-width: 1080px) {
   // margin: 0 0 0 24px;
    font-size: 90px;
    line-height: 1.3;
    text-align: left;
  }
}

.hero-mainText > :first-child {
  margin-top: 0
}
.heroMainText > :last-child {
  margin-bottom: 0
}
.heroMainText p {
  font-weight: 700;
  font-size: 22px;
  line-height: 160%;
  color: #FFFFFF;

  @media (min-width: 1080px) {
    font-size: 34px;
  }
}
.heroMainText p:nth-child(1) {
  margin-right: 15px;
  //text-align: right
}

.heroMainTextNM p:nth-child(1) {
  margin-right: 60px;
}

.heroMainText p:nth-child(3) {
  margin-left: 70px;
  //text-align: right
}
.heroMainText b {
  font-weight: 700
}

@media (max-width: 1079px) {

  .heroMainText p {
    margin: 0
  }
  .heroMainText p:nth-child(1) {
    margin-right: 10px
  }

  .heroMainTextNM p:nth-child(1) {
    margin-right: 40px;
  }

  .heroMainText p:nth-child(3) {
    margin-left: 0
  }
}

.heroMainText {
  width: 100%;
  color: #fff;
  //text-transform: uppercase;
  max-width: 380px;
  margin-top: 4px;
  font-size: 22px;
  line-height: 1.6;
  //text-align: center;
  display: inline-block;
  vertical-align: top;

  @media (min-width: 1080px) {
    margin-top: 30px;
    font-size: 36px;
    line-height: 1.3;
    max-width: 580px;
  }
}

.heroMainText span {
  position: relative;
  z-index: 1;
}

.heroMainText span:before {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 11px;
  background: #2ACEEE;
  z-index: -1;

  @media (min-width: 1080px) {
    height: 22px;
  }
}

.heroMainTextNM {
  max-width: 240px;
  font-size: 30px;

  @media (min-width: 600px) {
    max-width: 300px;
  }

  @media (min-width: 1320px) {
    max-width: 360px;
  }
}

.heroMainText p:nth-child(2) {
  text-align: left;

  @media (min-width: 600px) {
    margin-left: 15px;
  }
}

.heroMainFoot {
  margin-top: 23px;
  text-align: center;

  @media (min-width: 1080px) {
    margin-top: 56px;
    text-align: left;
  }
}

.heroMainButton {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  letter-spacing: .02em;
  text-transform: uppercase;
  outline: 0;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: url("/images/aboutUs/arrow22.svg") 76% 45% no-repeat, #193419;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  padding: 20px 0;
  width: 100%;
  border-radius: 30px;
  font-size: 16px;
  max-width: 280px;

  @media (min-width: 1080px) {
    padding: 20px 0;
    font-size: 20px;
  }

  @media (min-width: 1320px) {
    max-width: 340px;
  }
}

.heroMainButton:hover {
  background: url("/images/aboutUs/arrow22.svg") 76% 45% no-repeat, #183b18;
}

.heroMainLine {
  position: static;
  bottom: auto;
  margin-top: 42px;

  @media (min-width: 1080px) {
    position: absolute;
    bottom: 60px;
    left: 0;
  }
}

.heroMainLineLabel {
  line-height: 1.5;
  font-weight: 400;
  color: #fff;
  padding-left: 10px;
  font-size: 14px;
  margin-bottom: 10px;

  @media (min-width: 1079px) {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.heroMainSlider {
  position: relative;
  right: auto;
  bottom: auto;
  width: 100%;
  margin: 0 auto;
  z-index: 0;
  max-width: 710px;

  @media (min-width: 1080px) {
    position: absolute;
    right: 170px;
    bottom: -33px;
  }

  //@media (min-width: 1140px) {
  //  max-width: 750px;
  //}
  //
  //@media (min-width: 1230px) {
  //  right: 66px;
  //  bottom: -33px;
  //}
  //
  //@media (min-width: 1450px) {
  //  right: 170px;
  //}
}

.heroMainSliderTwo {
  @media (min-width: 1080px) {
    right: 0;
    bottom: -33px;
  }
}

.heroMainImgFirst {
  background-position: 46% 0 !important;
  background-size: 184% !important;
}

.heroMainImgSecond {
  background-position: 25% !important;
}

.heroMainImgSecond {
  background-position: 25% !important;
}

.heroMainImgThird {
  background-position: 36% !important;
  background-size: 166% !important;
}

.heroMainImg {
  width: 100%;
  background-position: 50% 0;
  background-size: cover;
  min-height: 295px;

  @media (min-width: 375px) {
    min-height: 350px;
  }

  @media (min-width: 400px) {
    min-height: 400px;
  }

  @media (min-width: 500px) {
    min-height: 650px;
  }

  @media (min-width: 800px) {
    min-height: 742px;
  }

  //@media (min-width: 1230px) {
  //  min-height: 742px;
  //}
}

.heroMainImgTwo {
  border-radius: 400px 0 400px 400px;

  @media (min-width: 800px) {
    min-height: 742px;
  }
}

.list {
  display: flex;
  justify-content: space-between;
  gap: 96px;
  border: 4px dashed #FFECA8;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 13px 0 10px;
  max-width: 100%;

  @media (min-width: 1024px) {
    max-width: 450px;
  }

  @media (min-width:1350px) {
    max-width: 514px;
    padding: 22px 0 21px;
  }
}

.item {
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;
}

.item:before {
  content: '';
  position: absolute;
  background: url("/images/aboutUs/icon1.svg") center no-repeat;
  right: -11px;
  width: 6px;
  height: 6px;

  @media (min-width: 375px) {
    right: -2px;
  }

  @media (min-width: 400px) {
    width: 10px;
    height: 10px;
    right: 10px;
  }
}

.textString {
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  white-space: nowrap;

  @media (min-width:1023px) {
    font-size: 20px;
  }
}

.image {
  @media (max-width:1023px) {
    width: 24px;
    height: 24px;
  }
}

.sliderWrapper div {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.slider {
  //max-width: 650px;

  @media (min-width: 1230px) {
    max-width: 760px;
  }
}

.swiperMain {
  position: relative;
  width: 100%;
  border-radius: 400px 0 400px 400px;
}

.sliderWrapper {
  position: relative;
}

.cursor {
  cursor: pointer;
}

.sliderWrapper {
  position: relative;
}

.caption-container {
  text-align: center;
  background-color: #222;
  padding: 2px 16px;
  color: white;
}

.row:after {
  content: '';
  display: table;
  clear: both;
}

.column {
  width: 50px;
  box-sizing: border-box;
  margin-right: 0!important;
  margin-left: 2px;
  border-radius: 50%;

  &:global(.swiper-slide-thumb-active) {
    .demo {
      opacity: 1;
      border: 1px solid #a1d3a2;
      width: 64px!important;
      height: 64px!important;
      transform: scale(0.95);
      background-color: #E0E0E0;
    }

    .circle .inner {
      animation: activeCircle 3s linear forwards;
    }

    .inner {
      background-color: #FFF5A4;
      clip-path: polygon(50% 0%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 50%, 50% 50%);
    }
  }

  @media (max-width: 1023px) {
    margin-right: -15px !important;
    width: 42px!important;
    height: 42px!important;
    margin-left: 6px;
    margin-top: 5px;
    margin-bottom: 5px;

    &:global(.swiper-slide-thumb-active) {
      z-index: 12!important;
      .demo {
        width: 46px!important;
        height: 46px!important;
        z-index: 1;
      }
    }
  }
  @media (max-width: 400px) {
    margin-right: -22px !important;
    &:global(.swiper-slide-thumb-active) {

    }
  }
}

.column:nth-child(3) {
  @media (max-width: 1023px) {
    margin-right: 30px !important;
  }
}

.columnLast {
  margin-right: 0;
}

.demo {
  opacity: 0.5;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  width: 42px!important;
  height: 42px!important;
  z-index: 0;

  @media (min-width: 1024px) {
    width: 50px!important;
    height: 50px!important;
  }
}

.demo:hover {
  opacity: 1;
  border: 1px solid #a1d3a2;
}

.column:last-child {
  margin-right: 0 !important;
}

.rowWrapper div {
  display: flex;
  width: 110px;
  align-items: center;

  @media (min-width: 400px) {
    width: 120px;
  }

  @media (min-width: 1024px) {
    width: 214px;
  }
}

.sliderImg {
  max-width: 100%;
  width: 757.53px;
  height: 742px;
  display: block;
  margin: 0 auto;
  border-radius: 400px 0 400px 400px;
}

.mySlides {
  width: 452px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    width: 100%;
  }
}

.prev,
.next {
  margin-top: -15px;

  @media (min-width: 1024px) {
    margin-top: -20px;
  }
}

.introContainerSlider {
  max-width: 416px;
  margin: 0 auto;
  position: relative;

  @media (min-width: 1024px) {
    max-width: 416px;
  }
}

.row {
  position: absolute;
  bottom: -40px;
  right: -10px;

  @media (min-width: 375px) {
    right: -19px;
  }

  @media (min-width: 500px) {
    bottom: -10px;
  }

  @media (min-width: 1024px) {
    right: -170px;
    bottom: 75px;
  }

  //@media (min-width: 1140px) {
  //  bottom: -6px;
  //}
  //
  //@media (min-width: 1230px) {
  //  right: -85px;
  //  bottom: 38px;
  //}
  //
  //@media (min-width: 1450px) {
  //  bottom: 75px;
  //  right: -170px;
  //}
}

.small {
  height: 0;
}

.circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.circle>div {
  width: 119%;
  height: 70%;
  position: absolute;
  border-radius: 50%;

  @media (min-width: 1024px) {
    width: 107%;
    height: 97%;
  }
}

@keyframes activeCircle {
  12.5% {
    clip-path: polygon(50% 0%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 50%, 50% 50%);
  }
  25% {
    clip-path: polygon(50% 0%, 100% 0, 100% 0, 100% 0, 100% 0, 100% 0, 100% 0, 50% 50%, 50% 50%);
  }
  37.5% {
    clip-path: polygon(50% 0%, 100% 0, 100% 50%, 100% 50%, 100% 50%, 100% 50%, 100% 50%, 50% 50%, 50% 50%);
  }
  50% {
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 50%, 50% 50%);
  }
  62.5% {
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 50%, 50% 50%);
  }
  75% {
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 100%, 0 100%, 0 100%, 50% 50%, 50% 50%);
  }
  87.5% {
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 100%, 0 50%, 0 50%, 50% 50%, 50% 50%);
  }
  100% {
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 0, 0 0, 50% 0, 50% 50%);
  }
}

.heroMainHeadlineTwo {
  font-weight: 700;
  font-size: 42px;
  line-height: 110%;
  color: #FFFFFF;
  max-width: 325px;
  margin: 0 auto 20px;

  @media (min-width: 1080px) {
    font-size: 64px;
    max-width: 600px;
    margin: 0 0 31px 0;
  }
}

.heroMainTextTwoMobile {
  @media (min-width: 1080px) {
    display: none;
  }
}

.heroMainImgTwoDesktop {
  display: none;

  @media (min-width: 1080px) {
    display: block;
  }
}

.heroMainTextTwo {
  max-width: 320px;
  margin: 0 auto;

  @media (min-width: 1080px) {
    margin: 0;
    font-size: 32px;
    max-width: 600px;
  }
}

.heroMainTextTwo p {
  font-weight: 500;
  font-size: 26px;
  line-height: 150%;
  color: #FFFFFF;

  @media (min-width: 1080px) {
    font-size: 32px;
    line-height: 120%;
  }
}

.heroMainContentTwo {
  max-width: 100%;

  @media (min-width: 1080px) {
    max-width: 600px;
  }
}

.sliderWrapperTwo div div {
  margin: 0 30px;
}

.itemTwo:nth-child(3):before {
  display: none;
}

.textStringTwo {
  font-size: 8px;

  @media (min-width: 420px) {
    font-size: 10px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }
}

.itemTwo:before {
  width: 4px;
  height: 4px;
  right: -10px;

  @media (min-width: 375px) {
    width: 6px;
    height: 6px;
    right: -20px;
  }

  @media (min-width: 1024px) {
    right: -25px;
    width: 10px;
    height: 10px;
  }

}

.listTwo {
  max-width: 550px;
  gap: 10px;
  padding: 13px 6px 10px;

  @media (min-width: 420px) {
    padding: 13px 10px 10px;
  }

  @media (min-width: 1024px) {
    gap: 40px;
    padding: 22px 22px 21px;
  }
}