.sectionMain {
  padding: 60px 0;

  @media (min-width: 1080px) {
    padding: 110px 0;
  }
}

.sectionMainContainer {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 1550px) {
    max-width: 1300px;
  }
}

.sectionMainHeader {
  text-align: center;
  margin-bottom: 34px;

  @media (min-width: 1080px) {
    margin-bottom: 60px;
    text-align: left;
  }
}
.sectionMainHeadline {
  margin: 0;
  font-weight: 400;
  font-size: 34px;
  line-height: 1.2;
  color: var(--text-base);
  text-transform: uppercase;

  @media (min-width: 1080px) {
    font-size: 36px;
    line-height: 1.4;
  }
}

.sectionMainHeadline br {
  display: initial;

  @media (min-width: 1080px) {
    display: none;
  }
}

.sectionMainHeadline b,
.sectionMainHeadline strong {
  font-weight: 700;
}

.sectionMainText {
  display: flex;
  align-items: center;
  color: var(--blue-grey-color-200);
  font-size: 14px;
  justify-content: center;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 12px;
  gap: 12px;

  @media (min-width: 1080px) {
    font-size: 24px;
    justify-content: left;
  }
}

.sectionMainFlag {
  width: 35px;
  height: 26px;
}

.sectionMainFlag img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}

.sectionMainFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media (min-width: 1080px) {
    margin-top: 80px;
  }
}

.sectionMainMore {
  outline: 0;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border: 0;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 0;
  background-color: #4faf4d;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: .02em;
  text-transform: uppercase;
  text-align: center;
  border-radius: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: 1080px) {
    padding: 22px 58px;
    font-size: 20px;
    line-height: 26px;
  }
}

.sectionMainMore:hover {
  background-color: #53cc51;
}
.sectionMainMore:active {
  background-color: #47a245;
}

.steps {
  position: relative;
  display: flex;
}
.stepsContent {
  flex: 1;
}

.stepItem {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;

  @media (min-width: 1080px) {
    gap: 18px;
  }
}

.stepItem:last-child {
  margin-bottom: 0;
}

.stepsGroup {
  display: block;

  @media (min-width: 1080px) {
    display: flex;
    gap: 74px;
  }
}

.stepsGroup:not(:last-child) {
  margin-bottom: 20px;

  @media (min-width: 1080px) {
    margin-bottom: 20px;
  }
}

.stepsSide {
  display: none;

  @media (min-width: 1080px) {
    width: 570px;
    margin-left: 74px;
  }
}

.stepsSidePic {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  width: 100%;
  height: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bdedf6;
  border-radius: 30px;
}
.stepsSidePic img {
  max-width: 100%;
  max-height: 100%;
}
.stepsPic {
  min-width: 570px;
  height: 640px;
  position: relative;

  @media (max-width: 600px) {
    min-width: 280px;
  }
}

@media (max-width: 1079px) {
  .stepsPic {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 403px;
    //background: #bdedf6;
    border-radius: 16px;
    margin-top: 40px;
  }
  .stepsPic img {
    max-width: 100%;
    max-height: 100%;
  }
}

.stepsNumber {
  font-size: 60px;
  margin-bottom: 0;
  color: var(--text-base);
  line-height: 1.4;
  font-weight: 700;

  @media (min-width: 1080px) {
    font-size: 62px;
    margin-bottom: 10px;
  }
}

//.stepsHead span {
//  font-weight: 700;
//  color: var(--vitaliv);
//}

.stepsHead {
  color: var(--text-base);
  letter-spacing: 0.02em;
  font-size: 22px;
  line-height: 1.5;
  font-weight: 700;
  margin: 0;

  @media (min-width: 1080px) {
    font-size: 24px;
    line-height: 1.6;
  }
}

@media (max-width: 1079px) {

  .stepsHead br {
    display: none;
  }
}

.stepsText {
  color: var(--text-mid);
  font-size: 16px;
  line-height: 1.4;
  margin-top: 10px;
  font-weight: 400;

  @media (min-width: 1080px) {
    margin-top: 24px;
    font-size: 18px;
    line-height: 1.5;
  }
}

.stepsFoot {
  margin-top: 24px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 1080px) {
    margin-top: 56px;
    display: block;
  }
}

@media (max-width: 1079px) {

  .stepsFoot > :not(.steps__hint) {
    order: 2;
  }
}

.stepsBanner {
  border-radius: 16px;
  color: #232E23;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 21px;
  font-size: 14px;
  padding: 34px 16px;
  max-width: 100%;
  line-height: 1.3;
  letter-spacing: 0.02em;
  font-weight: 400;
  background: #FFEBA8;

  @media (min-width: 1080px) {
    gap: 20px;
    border-radius: 30px;
    padding: 34px 48px;
    font-size: 16px;
    max-width: 485px;
  }
}

.price {
  font-weight: 700;
  font-size: 46px;
  line-height: 59px;
  color: #232E23;

  @media (min-width: 1080px) {
    font-size: 50px;
    line-height: 64px;
  }
}

.bannerTextFirst {
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #232E23;
  margin-bottom: 4px;

  @media (min-width: 1080px) {
    font-size: 16px;
    margin-bottom: 2px;
  }
}

.bannerTextSecond {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #73818A;

  @media (min-width: 1080px) {
    font-size: 16px;
  }
}

.stepsButton {
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 0;
  outline: 0;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border: 0;
  background: url("/images/aboutUs/arrow22.svg") 91% 47% no-repeat, #4faf4d;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  border-radius: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: 1080px) {
    padding: 22px 58px 22px 45px;
    font-size: 20px;
    line-height: 26px;
    width: auto;
    background: url("/images/aboutUs/arrow22.svg") 90% 47% no-repeat, #4faf4d;
  }
}

.stepsButton:hover {
  background-color: #53cc51;
}
.stepsButton:active {
  background-color: #47a245;
}

.blockBannerIndex {
  border: 4px dashed #69AC59;
  border-radius: 30px;
  padding: 34px 25px 31px;
  max-width: 479px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media (min-width: 1080px) {
    padding: 35px 45px;
    margin-top: 50px;
  }
}

.bannerTitleIndex {
  font-weight: 700;
  font-size: 46px;
  line-height: 59px;
  color: #69AC59;

  @media (min-width: 1080px) {
    font-size: 50px;
    line-height: 64px;
  }
}

.bannerSubtitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #232E23;
  margin-bottom: 2px;

  @media (min-width: 1080px) {
    font-size: 16px;
  }
}

.bannerSubText {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #669E8D;

  @media (min-width: 1080px) {
    font-size: 16px;
  }
}

.stepList {
  border-radius: 16px;
  border: 2px dashed #69ac59;
  padding: 32px 20px;

  @media (min-width: 1080px) {
    border-radius: 30px;
    border: 4px dashed #69ac59;
    padding: 40px;
  }
}

.stepListItem {
  display: flex;
  align-items: center;
}

.stepListItem:not(:last-child) {
  margin-bottom: 16px;
}

.stepListIcon img {
  max-width: 100%;
  max-height: 100%;
}

.stepListIcon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  color: #fff;
  background-color: var(--vitaliv);

  @media (min-width: 1080px) {
    width: 44px;
    height: 44px;
    margin-right: 18px;
  }
}


.stepListText {
  font-size: 14px;
  line-height: 1.4;
  color: var(--text-mid);
  font-weight: 400;
  flex: 1;

  @media (min-width: 1080px) {
    font-size: 18px;
    line-height: 1.5;
  }
}

.mainHeadline {
  font-weight: 700;
  font-size: 34px;
  line-height: 120%;
  text-align: center;
  color: #2C2C2A;
  //max-width: 278px;
  margin: 0 auto;

  @media (min-width: 1080px) {
    font-size: 70px;
    line-height: 140%;
    max-width: 100%;
    margin-left: 200px;
    text-align: left;
  }
}

.listIconClub {
  width: 10px;
  height: 10px;
  margin-right: 0;
}

.itemClub {
  align-items: baseline;
}

.stepsHead span {
  position: relative;
  z-index: 1;
  white-space: nowrap;
}

.stepsHead span:before {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: #81E47F;
  z-index: -1;

  @media (min-width: 1080px) {
    height: 11px;
  }
}

.wrapper {
  max-width: 289px;

  @media (min-width: 1080px) {
    display: flex;
    flex-direction: column-reverse;
    max-width: 100%;
  }
}

.hint {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #73818A;
  position: relative;
  margin-bottom: 32px;

  @media (min-width: 1080px) {
    font-size: 16px;
    margin-bottom: 12px;
    align-self: center;
    margin-top: 37px;
  }
}

.hintArrow {
  position: absolute;
  top: -12px;
  right: 20px;
  background: url("/images/VitalivClub/arrow12.svg") center no-repeat;
  width: 70px;
  height: 70px;

  @media (min-width: 340px) {
    right: 30px;
  }

  @media (min-width: 1080px) {
    background: url("/images/VitalivClub/arrow3Desktop.svg") center no-repeat;
    top: -40px;
    right: 0;
    left: -75px;
  }
}

.blockBanner {
  background: #FFEBA8;
  border-radius: 16px;
  padding: 20px 10px;
  display: flex;
  gap: 10px;
  align-items: center;

  @media (min-width: 1080px) {
    padding: 34px 30px;
    gap: 30px;
    max-width: 371px;
  }
}

.bannerTitle {
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;
  color: #232E23;

  @media (min-width: 1080px) {
    font-size: 50px;
    line-height: 64px;
  }
}

.bannerSubtitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #232E23;

  @media (min-width: 1080px) {
    font-size: 16px;
  }
}